import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { tablet, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Typography from '@/components/common/Typography'
import Bg from '@/components/app/Bg'
import Tag from '@/components/common/Tag'

const OptionCard = props => {
  const { name, icon, iconSize, required, body, ...others } = props
  return (
    <Root {...others}>
      <Head>
        <Name variant="h5" theme={props.theme}>{name}</Name>
        {required && (
          <Required>
            <Tag color="primary">必須</Tag>
          </Required>
        )}
      </Head>
      <Main>
        <Icon theme={props.theme} iconSize={iconSize}>{icon}</Icon>
        <Body variant="body2">{body}</Body>
      </Main>
    </Root>
  )
}

export default ThemeConsumer(OptionCard)

const Root = styled.div`
  padding: 32px;
  @media ${mq.and(mobile)} {
    padding: 24px 16px;
  }
`

const Head = styled.div`
  display: flex;
  align-items: center;
`

const Name = styled(Typography)`
  color: ${props => props.theme.gray.e};
`

const Required = styled.div`
  margin-left: 40px;
  @media ${mq.and(mobile)} {
    margin-left: 16px;
  }
`

const Main = styled.div`
  margin-top: 16px;
  display: flex;
`

const Icon = styled(Bg)`
  flex-grow: 0;
  flex-shrink: 0;
  width: 130px;
  height: 130px;
  padding: ${props => props.iconSize === 'large' ? '12px' : '20px'};
  @media ${mq.and(tablet)} {
    width: 100px;
    height: 100px;
  }
  @media ${mq.and(mobile)} {
    width: 80px;
    height: 80px;
  }
  background-color: ${props => props.theme.gray.b};
  display: flex;
  justify-content: center;
  align-items: center;

  > * {
    width: 100%;
    height: 100%;
  }
`

const Body = styled(Typography)`
  margin-left: 40px;
  @media ${mq.and(mobile)} {
    margin-left: 16px;
  }
`
