import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { layouts } from '@/utils/preset'
import { tablet, tabletS, mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import { getThemeColor } from '@/utils/themes'
import Typography from '@/components/common/Typography'
import Headline from '@/components/app/Headline'

const PricingBase = props => {
  return (
    <Root {...props}>
      <Head>
        <Title theme={props.theme}>
          基本料金
        </Title>
      </Head>
      <Main>
        <Body variant="body1">
          FVPでは、お客様の要望に合わせて<br />様々な導入・サポートプランをご用意しています。
        </Body>
        <Price theme={props.theme}>
          <Price_Head theme={props.theme}>
            <Price_Title variant="body1">月額</Price_Title>
          </Price_Head>
          <Price_Body>
            <Price_Value theme={props.theme}>50,000<small>円 〜 / 月</small></Price_Value>
          </Price_Body>
        </Price>
        <Caption variant="body3">
          基本料金内ですべての機能が利用可能です。<br />
          ただし、予想データ量が多いと判断される場合は、サーバ環境など個別にご用意をお願いする場合がございます。<br />
          また、ユーザライセンス無制限で使い放題にするプランや月額30,000円でご利用いただけるプランもございます。<br />
          詳細はお問い合せください。
        </Caption>
      </Main>
    </Root>
  )
}

const Root = styled.div`
  padding: 100px 0 120px;
  @media ${mq.and(mobile)} {
    padding: 48px 0 64px;
  }
`

const Head = styled.div``

const Title = styled(Headline)`
  strong {
    color: ${props => props.theme.primary.base};
  }
  @media ${mq.and(mobileS)} {
    br {
      display: none;
    }
  }
`

const Main = styled.div`
  margin-top: 40px;
  @media ${mq.and(mobile)} {
    margin-top: 32px;
  }
`

const Body = styled(Typography)`
  margin: 0 auto;
  max-width: ${layouts.defs.contentMaxWidth.base}px;
  text-align: center;
  @media ${mq.and(tabletS)} {
    text-align: left;
    max-width: ${layouts.defs.contentMaxWidth.small}px;
  }
  @media ${mq.and(mobile)} {
    text-align: left;
    br {
      display: none;
    }
  }
`

const Price = styled.div`
  margin-top: 40px;
  display: flex;
  border: 1px solid ${props => props.theme.line.base};
  @media ${mq.and(mobile)} {
    margin-top: 32px;
    display: block;
  }
`

const Price_Head = styled.div`
  width: 290px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.gray.b};
  @media ${mq.and(mobile)} {
    width: 100%;
    padding: 16px;
  }
`

const Price_Title = styled(Typography)``

const Price_Body = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 52px 24px;
`

const Price_Value = styled.div`
  margin-top: 10px;
  text-align: center;
  font-size: 44px;
  @media ${mq.and(tablet, mobile)} {
    font-size: 40px;
  }
  font-weight: bold;
  color: ${props => props.color
    ? getThemeColor(props.color, props.theme, 'contrast')
    : props.theme.primary.base
  };
  small {
    font-size: 0.4em;
  }
`

const Caption = styled(Typography)`
  margin-top: 16px;
`

export default ThemeConsumer(PricingBase)
