import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { aspectRatio, aspectRatioChild } from '@/utils/helpers'

const Transfer = props => (
  <Root {...props}>
    <Svg size={props.size} viewBox="0 0 57 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.5 5.00001C2.5 3.61929 3.61929 2.5 5 2.5H32.685C33.3481 2.5 33.984 2.76339 34.4528 3.23223L36.1954 1.4896L34.4528 3.23223L53.7678 22.5472C54.2366 23.016 54.5 23.6519 54.5 24.315V74.939C54.5 76.3197 53.3807 77.439 52 77.439H5C3.61929 77.439 2.5 76.3197 2.5 74.939V5.00001Z" fill="white" stroke="#47B347" strokeWidth="5"/>
      <path d="M34.7559 0L56.9998 22.2439H34.7559V0Z" fill="#47B347"/>
    </Svg>
  </Root>
)

const Root = styled.div`
  ${props => props.size === 'contain' ? null : aspectRatio(57, 80)};
`

const Svg = styled.svg`
  ${props => props.size === 'contain'
    ? css`
      width: 100%;
      height: 100%;
    `
    : aspectRatioChild()};
`

export default Transfer
