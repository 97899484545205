import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { aspectRatio, aspectRatioChild } from '@/utils/helpers'

const ConsultingIcon = props => (
  <Root {...props}>
    <Svg size={props.size} viewBox="0 0 72 71" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.5" y="2.5" width="67" height="45" rx="2.4" fill="white" stroke="#47B347" strokeWidth="5"/>
      <path d="M35.1011 50.9341L38.6366 54.4696L22.5711 70.5352L19.0355 66.9996L35.1011 50.9341Z" fill="#7FCA7F"/>
      <path d="M50.4521 70.5352L53.9877 66.9996L38.1593 51.1712L34.6237 54.7067L50.4521 70.5352Z" fill="#7FCA7F"/>
      <rect x="34" y="50" width="5" height="20" fill="#47B347"/>
      <rect x="34" y="15" width="5" height="20" fill="#47B347"/>
      <rect x="44" y="25" width="5" height="10" fill="#A3D9A3"/>
      <rect x="24" y="21" width="5" height="14" fill="#7FCA7F"/>
    </Svg>
  </Root>
)

const Root = styled.div`
  ${props => props.size === 'contain' ? null : aspectRatio(72, 71)};
`

const Svg = styled.svg`
  ${props => props.size === 'contain'
    ? css`
      width: 100%;
      height: 100%;
    `
    : aspectRatioChild()};
`

export default ConsultingIcon
