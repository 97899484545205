import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { desktop, laptop, tabletL, tabletS, mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Typography from '@/components/common/Typography'
import RegisterIcon from '@/components/svg/RegisterIcon'
import ConsultingIcon from '@/components/svg/ConsultingIcon'
import ExtensionIcon from '@/components/svg/ExtensionIcon'
import TransferIcon from '@/components/svg/TransferIcon'
import CustomizeIcon from '@/components/svg/CustomizeIcon'
import SubsidyIcon from '@/components/svg/SubsidyIcon'
import OptionCard from './OptionCard'
import LinkButton1 from '@/components/common/LinkButton1'

const OptionsBefore = props => {
  const { ...others } = props
  return (
    <Root {...others}>
      <Main theme={props.theme}>
        <Headline variant="h4" theme={props.theme}>導入サポートオプション</Headline>
        <List theme={props.theme}>
          <List_Container>
            <List_Item theme={props.theme}>
              <OptionCard
                name="システム登録料"
                icon={(
                  <RegisterIcon />
                )}
                required
                body={(
                  <Fragment>
                    30,000円（税別）、初回利用開始時のみ。
                  </Fragment>
                )}
              />
            </List_Item>
            <List_Item theme={props.theme}>
              <OptionCard
                name="導入支援・コンサルティング"
                icon={(
                  <ConsultingIcon />
                )}
                body={(
                  <Fragment>
                    現場の業務分析と改善提案／システムの活用提案／あるべき姿や目標値の設定など、中小企業診断士や税理士資格を持つコンサルタントが、お客様の課題に合った導入プランを提示いたします。
                  </Fragment>
                )}
              />
            </List_Item>
            <List_Item theme={props.theme}>
              <OptionCard
                name="拡張・連携機能開発"
                icon={(
                  <ExtensionIcon />
                )}
                body={(
                  <Fragment>
                    標準機能ではカバーしきれない細かい業務内容にフィットさせるための拡張機能の開発や、他システムとのデータ連携機能の開発を行います。内容に応じて個別の見積となります。
                  </Fragment>
                )}
              />
            </List_Item>
            <List_Item theme={props.theme}>
              <OptionCard
                name="既存データ移行"
                icon={(
                  <TransferIcon />
                )}
                body={(
                  <Fragment>
                    既存システムからのデータ移行／新システム操作方法などの業務移行をサポート・代行致します。
                  </Fragment>
                )}
              />
            </List_Item>
            <List_Item theme={props.theme}>
              <OptionCard
                name="帳票デザイン・カスタマイズ"
                icon={(
                  <CustomizeIcon />
                )}
                body={(
                  <Fragment>
                    見積書・発注書・請求書など、各種帳票へのカスタム項目の追加や、オリジナルのデザインテンプレートの作成を行います。
                  </Fragment>
                )}
              />
            </List_Item>
            <List_Item theme={props.theme}>
              <OptionCard
                name="補助金申請サポート"
                icon={(
                  <SubsidyIcon />
                )}
                iconSize="large"
                body={(
                  <Fragment>
                    中小企業・小規模事業者等の業務効率化・売上アップ等を目的とする、IT導入補助金の採択支援を行います。詳細はお問合せください。
                  </Fragment>
                )}
              />
            </List_Item>
          </List_Container>
        </List>
      </Main>
      <Foot>
        <MoreButton to="/contact/" component={Link} color="primary">お見積もりはこちら</MoreButton>
      </Foot>
    </Root>
  )
}

export default ThemeConsumer(OptionsBefore)

const Root = styled.div`
`

const Main = styled.div`
  border: 1px solid ${props => props.theme.line.base};
`

const Headline = styled(Typography)`
  padding: 20px 24px;
  text-align: center;
  color: ${props => props.theme.gray.e};
`

const List = styled.div`
  border-top: 1px solid ${props => props.theme.line.base};
`

const List_Container = styled.ul`
  display: flex;
  flex-wrap: wrap;
`

const List_Item = styled.li`
  @media ${mq.and(desktop, laptop, tabletL)} {
    width: 50%;
    &:nth-of-type(even) {
      border-left: 1px solid ${props => props.theme.line.base};
    }
    &:nth-of-type(n+3) {
      border-top: 1px solid ${props => props.theme.line.base};
    }
  }
  @media ${mq.and(tabletS, mobile)} {
    width: 100%;
    &:nth-of-type(n+2) {
      border-top: 1px solid ${props => props.theme.line.base};
    }
  }
`

const Foot = styled.div`
  margin-top: 64px;
  display: flex;
  justify-content: center;
  @media ${mq.and(mobile)} {
    margin-top: 32px;
  }
`

const MoreButton = styled(LinkButton1)`
  width: 278px;
  @media ${mq.and(mobileS)} {
    width: 100%;
  }
`
