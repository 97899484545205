import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
// import { layouts } from '@/utils/preset'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Headline from '@/components/app/Headline'
// import Typography from '@/components/common/Typography'
import OptionsBefore from './OptionsBefore'
import OptionsAfter from './OptionsAfter'

const PricingOptions = props => {
  return (
    <Root {...props}>
      <Head>
        <Title theme={props.theme}>
          オプション
        </Title>
      </Head>
      <Main>
        <Row><OptionsBefore /></Row>
        <Row><OptionsAfter /></Row>
      </Main>
    </Root>
  )
}

const Root = styled.div`
  padding: 100px 0 120px;
  @media ${mq.and(mobile)} {
    padding: 48px 0 64px;
  }
`

const Head = styled.div``

const Title = styled(Headline)`
  strong {
    color: ${props => props.theme.primary.base};
  }
`

const Main = styled.div`
  margin-top: 40px;
  @media ${mq.and(mobile)} {
    margin-top: 32px;
  }
`

const Row = styled.div`
  &:not(first-of-type) {
    margin-top: 100px;
    @media ${mq.and(mobile)} {
      margin-top: 64px;
    }
  }
`

export default ThemeConsumer(PricingOptions)
