import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { aspectRatio, aspectRatioChild } from '@/utils/helpers'

const RegisterIcon = props => (
  <Root {...props}>
    <Svg size={props.size} viewBox="0 0 94 71" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="3" width="88" height="65" rx="5" fill="white"/>
      <rect x="3" y="25" width="88" height="5" fill="#A3D9A3"/>
      <rect x="4" y="3" width="86" height="22" fill="#EDF7ED"/>
      <path d="M91 35.2292C91 25.4956 91 14.296 91 7.99205C91 5.23063 88.7614 3 86 3H8C5.23858 3 3 5.23858 3 8V63C3 65.7614 5.23858 68 8 68H47" stroke="#47B347" strokeWidth="5"/>
      <path d="M50.8486 49.8489C57.0426 49.8489 71.8777 49.8489 78.0717 49.8489" stroke="#A3D9A3" strokeWidth="5" strokeDasharray="5 5"/>
      <path d="M75.6724 45.0341L83.6969 49.8478L75.6724 54.6636L75.6724 45.0341Z" fill="#47B347" stroke="#47B347"/>
    </Svg>
  </Root>
)

const Root = styled.div`
  ${aspectRatio(94, 71)};
  ${props => props.size === 'contain' ? null : aspectRatio(94, 71)};
`

const Svg = styled.svg`
  ${props => props.size === 'contain'
    ? css`
      width: 100%;
      height: 100%;
    `
    : aspectRatioChild()};
`

export default RegisterIcon
