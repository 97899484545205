import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import PageBase from '@/components/common/Page'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import SEO from '@/components/head/seo'
import LD from '@/components/head/linking-data'
import LDEntity from '@/utils/linking-data'
import { layouts } from '@/utils/preset'
import Breadcrumb from '@/components/app/Breadcrumb.container'
import PageHead from '@/components/app/PageHead'
import PricingContract from '@/components/modules/Pricing/Contract'
import PricingBase from '@/components/modules/Pricing/Base'
import PricingOptions from '@/components/modules/Pricing/Options'
import ConversionLead from '@/components/modules/Conversion/Lead'

const PricingIndexPage = props => (
  <StaticQuery
    query={query}
    render={data => {
      const siteMeta = data.site.siteMetadata
      const title = '料金'
      const description = '数十人から数百人規模の企業まで。単発のプロジェクトから、会社の運用基盤としてまで。用途に合わせて柔軟にご利用いただけます。'
      return (
        <Root>
          <SEO location={props.location} title={title} description={description}/>
          <LD json={{
            '@type': 'WebPage',
            '@id': `${siteMeta.siteUrl}/pricing/#webpage`,
            name: `${title} | ${siteMeta.title}`,
            description: description,
            url: `${siteMeta.siteUrl}/pricing/`,
            inLanguage: 'ja',
            datePublished: '2019-11-10T10:00:00+09:00',
            dateModified: '2019-11-10T10:00:00+09:00',
            isPartOf: LDEntity.WebSite(siteMeta)
          }}/>
          <Breadcrumb path={props.location.pathname} />
          <Head>
            <PageHead
              title={title}
              description={description}
            />
          </Head>
          <Main>
            <PricingContract />
            <Border theme={props.theme} />
            <PricingBase />
            <Border theme={props.theme} />
            <PricingOptions />
            <Border theme={props.theme} />
            <ConversionLead />
          </Main>
        </Root>
      )
    }}
  />
)

const Root = styled.div`
`

const Head = styled.div``

const Main = styled.div``

const Border = styled(layouts.components.FullWidth)`
  height: 1px;
  background-color: ${props => props.theme.line.base};
`

export default PageBase(ThemeConsumer(PricingIndexPage))

const query = graphql`
  query PricingIndexPageQuery {
    site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image
        company {
          name
          logo
          siteUrl
        }
      }
    }
  }
`
