import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { aspectRatio, aspectRatioChild } from '@/utils/helpers'

const CustomizeIcon = props => (
  <Root {...props}>
    <Svg size={props.size} viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.5" y="2.5" width="29" height="71" rx="2.4" fill="white" stroke="#47B347" strokeWidth="5"/>
      <rect x="44.5" y="2.5" width="29" height="29" rx="2.4" fill="#D1ECD1" stroke="#47B347" strokeWidth="5"/>
      <rect x="44.5" y="44.5" width="29" height="29" rx="2.4" fill="white" stroke="#47B347" strokeWidth="5"/>
    </Svg>
  </Root>
)

const Root = styled.div`
  ${props => props.size === 'contain' ? null : aspectRatio(1, 1)};
`

const Svg = styled.svg`
  ${props => props.size === 'contain'
    ? css`
      width: 100%;
      height: 100%;
    `
    : aspectRatioChild()};
`

export default CustomizeIcon
