import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { layouts } from '@/utils/preset'
import { desktop, laptop, tablet, tabletS, mobile, mobileL, mobileM, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import { Link } from 'gatsby'
import Headline from '@/components/app/Headline'
import Typography from '@/components/common/Typography'
import Select from '@/components/svg/Select'
import Transfer from '@/components/svg/Transfer'
import Subsidy from '@/components/svg/Subsidy'
import LinkButton1 from '@/components/common/LinkButton1'
import ContractCard from './ContractCard'

const AboutLead = props => {
  const { theme } = props
  return (
    <Root {...props}>
      <Head>
        <Title theme={theme}>
          はじめやすい契約形態
        </Title>
      </Head>
      <Main>
        <Body variant="body1">
          補助金を活用して初期費用を抑えたり、しっかりと旧システムからのデータ移行を実現したり、ご要望に合わせたプランのご提示をいたします。
        </Body>
        <CardList>
          <CardList_Container>
            <CardList_Item>
              <Card
                icon={(
                  <Select />
                )}
                body={(
                  <Fragment>
                    必要な機能のみに<br />絞って利用することも可能
                  </Fragment>
                )}
              />
            </CardList_Item>
            <CardList_Item>
              <Card
                icon={(
                  <Transfer />
                )}
                body={(
                  <Fragment>
                    データ移行支援や<br />柔軟なカスタマイズ提案
                  </Fragment>
                )}
              />
            </CardList_Item>
            <CardList_Item>
              <Card
                icon={(
                  <Subsidy />
                )}
                body={(
                  <Fragment>
                    補助金等の活用
                  </Fragment>
                )}
              />
            </CardList_Item>
          </CardList_Container>
        </CardList>
      </Main>
      <Foot>
        <MoreButton to="/contact/" component={Link} color="primary">お見積もりはこちら</MoreButton>
      </Foot>
    </Root>
  )
}

const Root = styled.div`
  padding: 100px 0 120px;
  @media ${mq.and(mobile)} {
    padding: 48px 0 64px;
  }
`

const Head = styled.div``

const Title = styled(Headline)`
  strong {
    color: ${props => props.theme.primary.base};
  }
  @media ${mq.and(mobileS)} {
    br:nth-of-type(2) {
      display: none;
    }
  }
`

const Main = styled.div`
  margin-top: 40px;
  @media ${mq.and(mobile)} {
    margin-top: 32px;
  }
`

const Body = styled(Typography)`
  margin: 0 auto;
  max-width: ${layouts.defs.contentMaxWidth.base}px;
  text-align: center;
  @media ${mq.and(tabletS)} {
    text-align: left;
    max-width: ${layouts.defs.contentMaxWidth.small}px;
  }
  @media ${mq.and(mobile)} {
    text-align: left;
    br {
      display: none;
    }
  }
`

const CardList = styled.div`
  margin: 48px auto 0;
  @media ${mq.and(mobile)} {
    margin-top: 32px;
  }
`

const CardList_Container = styled.ul`
  display: block;
  margin: 0 -8px;
  @media ${mq.and(mobileL)} {
    margin: -24px -8px 0;
  }
  @media ${mq.and(mobileM, mobileS)} {
    margin: -24px 0 0;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const CardList_Item = styled.li`
  padding: 0 8px;
  @media ${mq.and(desktop, laptop, tablet)} {
    width: ${100 / 3}%;
  }
  @media ${mq.and(mobileL)} {
    margin-top: 24px;
    width: 50%;
  }
  @media ${mq.and(mobileM, mobileS)} {
    padding: 0;
    margin-top: 24px;
    width: 100%;
  }
`

const Card = styled(ContractCard)`
`

const Foot = styled.div`
  margin-top: 64px;
  @media ${mq.and(mobile)} {
    margin-top: 32px;
  }
  display: flex;
  justify-content: center;
`

const MoreButton = styled(LinkButton1)`
  width: 278px;
  @media ${mq.and(mobileS)} {
    width: 100%;
  }
`

export default ThemeConsumer(AboutLead)
