import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { aspectRatio, aspectRatioChild } from '@/utils/helpers'

const Transfer = props => (
  <Root {...props}>
    <Svg size={props.size} viewBox="0 0 293 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M114 30C123.784 30 159.216 30 169 30" stroke="#828282" strokeWidth="5" strokeDasharray="5 5"/>
      <path d="M179 29.9981L164 39L164 21L179 29.9981Z" fill="#333333"/>
      <path d="M179 71C169.216 71 133.784 71 124 71" stroke="#828282" strokeWidth="5" strokeDasharray="5 5"/>
      <path d="M114 71.0019L129 62L129 80L114 71.0019Z" fill="#333333"/>
      <rect x="2.5" y="2.5" width="95" height="95" rx="2.4" fill="white" stroke="#47B347" strokeWidth="5"/>
      <rect y="32" width="100" height="5" fill="#47B347"/>
      <rect y="64" width="100" height="5" fill="#47B347"/>
      <rect x="75" y="14" width="10" height="10" rx="5" fill="#47B347"/>
      <rect x="5" y="37" width="90" height="27" fill="#D1ECD1"/>
      <rect x="75" y="77" width="10" height="10" rx="5" fill="#47B347"/>
      <rect x="75" y="46" width="10" height="10" rx="5" fill="#47B347"/>
      <rect x="15" y="46" width="50" height="10" rx="5" fill="#47B347"/>
      <rect x="195.5" y="2.5" width="95" height="95" rx="2.4" fill="white" stroke="#47B347" strokeWidth="5"/>
      <rect x="198" y="36" width="90" height="27" fill="#D1ECD1"/>
      <rect x="193" y="32" width="100" height="5" fill="#47B347"/>
      <rect x="193" y="64" width="100" height="5" fill="#47B347"/>
      <rect x="268" y="14" width="10" height="10" rx="5" fill="#47B347"/>
      <rect x="268" y="77" width="10" height="10" rx="5" fill="#47B347"/>
      <rect x="268" y="46" width="10" height="10" rx="5" fill="#47B347"/>
      <rect x="208" y="46" width="50" height="10" rx="5" fill="#47B347"/>
    </Svg>
  </Root>
)

const Root = styled.div`
${props => props.size === 'contain' ? null : aspectRatio(293, 100)};
`

const Svg = styled.svg`
  ${props => props.size === 'contain'
    ? css`
      width: 100%;
      height: 100%;
    `
    : aspectRatioChild()};
`

export default Transfer
