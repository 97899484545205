import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { tabletS, mobile, mobileL, mobileM, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Typography from '@/components/common/Typography'
import { getThemeColor } from '@/utils/themes'

const OptionsAfter = props => {
  const { ...others } = props
  return (
    <Root {...others}>
      <Main theme={props.theme}>
        <Headline variant="h4" theme={props.theme}>導入後のサポートオプション</Headline>
        <OptionList theme={props.theme}>
          <Option theme={props.theme}>
            <Option_Title variant="h5" theme={props.theme}>認定アドバイザーによるコンサルティング</Option_Title>
            <Option_Body variant="body2">使い方の相談やデータ整備依頼などさまざまな悩み対して、メール・チャット・電話などで直接サポートします。詳細はお問い合わせください。</Option_Body>
          </Option>
          <Option theme={props.theme}>
            <Option_Title variant="h5" theme={props.theme}>テクニカルサポート</Option_Title>
            <Option_Body variant="body2">テクニカルサポートにお申し込みいただくと、データ入力方法やリカバリ対応、各種お困りごとへの対応をリモートでサポートいたします。</Option_Body>
          </Option>
        </OptionList>
        <TableContainer>
          <Table theme={props.theme}>
            <TableHead>
              <TableRow>
                <TableHeader variant="body1" component="th" theme={props.theme}></TableHeader>
                <TableHeader variant="body1" component="th" theme={props.theme}>
                  スタンダード
                  <span>10,000円 / 月</span>
                </TableHeader>
                <TableHeader variant="body1" component="th" theme={props.theme}>
                  ミドル
                  <span>50,000円 / 月</span>
                </TableHeader>
                <TableHeader variant="body1" component="th" theme={props.theme}>
                  プレミアム
                  <span>200,000円〜 / 月</span>
                </TableHeader>
              </TableRow>
            </TableHead>
            <TableBody theme={props.theme}>
              <TableRow>
                <TableHeader variant="body2" component="th" theme={props.theme}>
                  メール
                </TableHeader>
                <TableCell variant="body2" component="td" theme={props.theme}>
                  <Score theme={props.theme}>○</Score>
                </TableCell>
                <TableCell variant="body2" component="td" theme={props.theme}>
                  <Score theme={props.theme}>○</Score>
                </TableCell>
                <TableCell variant="body2" component="td" theme={props.theme}>
                  <Score theme={props.theme}>○</Score>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableHeader variant="body2" component="th" theme={props.theme}>
                  電話
                </TableHeader>
                <TableCell variant="body2" component="td" theme={props.theme}></TableCell>
                <TableCell variant="body2" component="td" theme={props.theme}>
                  <Score theme={props.theme}>○</Score>
                </TableCell>
                <TableCell variant="body2" component="td" theme={props.theme}>
                  <Score theme={props.theme}>○</Score>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableHeader variant="body2" component="th" theme={props.theme}>
                  システム保守
                </TableHeader>
                <TableCell variant="body2" component="td" theme={props.theme}></TableCell>
                <TableCell variant="body2" component="td" theme={props.theme}></TableCell>
                <TableCell variant="body2" component="td" theme={props.theme}>
                  <Score theme={props.theme}>○</Score>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableHeader variant="body2" component="th" theme={props.theme}>
                  インシデント数制限
                </TableHeader>
                <TableCell variant="body2" component="td" theme={props.theme}>
                  <Desc>10回 / 月</Desc>
                </TableCell>
                <TableCell variant="body2" component="td" theme={props.theme}>
                  <Desc>10回 / 月</Desc>
                </TableCell>
                <TableCell variant="body2" component="td" theme={props.theme}>
                  <Desc>無制限</Desc>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Caption variant="body3" theme={props.theme}>
          ※ インシデントとはサポートの受け付け案件の最小単位です。<br />
          ※ システムへの改修やデータの一括操作などが発生するご要望は、サポート範囲外となります。<br />
          ※ システム保守はアドオン開発を実施していただいたお客様向けです。障害対応はもちろん、軽微なプログラム改修まで実施します。
        </Caption>
      </Main>
    </Root>
  )
}

export default ThemeConsumer(OptionsAfter)

const Root = styled.div`
`

const Main = styled.div`
  border: 1px solid ${props => props.theme.line.base};
`

const Headline = styled(Typography)`
  padding: 32px 24px;
  text-align: center;
  color: ${props => props.theme.gray.e};
`

const Option = styled.div`
  padding: 32px;
  @media ${mq.and(mobile)} {
    padding: 24px 16px;
  }
  &:not(:first-of-type) {
    border-top: 1px solid ${props => props.theme.line.base};
  }
`

const OptionList = styled.div`
  border-top: 1px solid ${props => props.theme.line.base};
`

const Option_Title = styled(Typography)`
  color: ${props => props.theme.gray.e};
`

const Option_Body = styled(Typography)`
  margin-top: 12px;
`

const TableContainer = styled.div`
  @media ${mq.and(tabletS, mobile)} {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }
`

const Table = styled.table`
  table-layout: fixed;
  width: 100%;
`

const Score = styled.div`
  color: ${props => props.theme.primary.base};
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  @media ${mq.and(mobile)} {
    font-size: 24px;
    line-height: 1.2;
  }
`

const Desc = styled.div`
  text-align: center;
  line-height: ${32 * 1.75}px;
  @media ${mq.and(mobile)} {
    line-height: ${24 * 1.75}px;
  }
`

const TableCell = styled(Typography)`
  padding: 32px;
  width: 1%;
  border: 1px solid ${props => props.theme.line.base};
  background-color: ${props => props.theme.gray.a};
  vertical-align: middle;
  @media ${mq.and(tabletS, mobileL)} {
    width: 180px;
    padding: 24px;
  }
  @media ${mq.and(mobileM, mobileS)} {
    width: 150px;
    padding: 8px;
    font-size: 12px;
    br {
      display: none;
    }
  }
  &:last-of-type {
    border-right: none;
  }
`

const TableHeader = styled(TableCell)`
  ${props => props.color && `color: ${getThemeColor(props.color, props.theme)}`};
  font-weight: bold;
  padding: 24px;
  span {
    display: block;
    white-space: nowrap;
  }
  @media ${mq.and(tabletS, mobileL)} {
    padding: 16px;
  }
  @media ${mq.and(mobileM, mobileS)} {
    padding: 8px;
  }
  &:nth-of-type(1) {
    position: sticky;
    left: 0;
    z-index: 1;
    font-weight: normal;
    border-left: none;
    @media ${mq.and(tabletS, mobile)} {
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: -1px;
        bottom: 0;
        background-color: ${props => props.theme.gray.b};
        width: 1px;
      }
    }
  }
`

const TableRow = styled.tr`
  &:last-of-type > * {
    border-bottom: none;
  }
`

const TableBody = styled.tbody`
  & ${TableHeader} {
    vertical-align: middle;
    background-color: ${props => props.theme.gray.b};
    @media ${mq.and(tabletS)} {
      width: 140px;
    }
    @media ${mq.and(mobileL)} {
      width: 120px;
    }
    @media ${mq.and(mobileM, mobileS)} {
      width: 100px;
    }
  }
`

const TableHead = styled.thead`
  & ${TableHeader} {
    text-align: center;
    &:nth-of-type(1) {
      @media ${mq.and(tabletS)} {
        width: 140px;
      }
      @media ${mq.and(mobileL)} {
        width: 120px;
      }
      @media ${mq.and(mobileM, mobileS)} {
        width: 100px;
      }
    }
  }
`

const Caption = styled(Typography)`
  border-top: 1px solid ${props => props.theme.line.base};
  padding: 32px;
  @media ${mq.and(mobile)} {
    padding: 24px 16px;
  }
`
