import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { mobile } from '@/utils/media'
import { mq, aspectRatio, aspectRatioChild } from '@/utils/helpers'
import Typography from '@/components/common/Typography'
import Bg from '@/components/app/Bg'

const ContractCard = props => {
  const { icon, body, ...other } = props
  return (
    <Root {...other}>
      <Head theme={props.theme}>
        <Icon><Icon_Content>{React.cloneElement(icon, { size: 'contain' })}</Icon_Content></Icon>
      </Head>
      <Main>
        <Body variant="body2">{body}</Body>
      </Main>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Head = styled(Bg)`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px;
  background-color: ${props => props.theme.gray.b};
  @media ${mq.and(mobile)} {
    padding: 24px;
  }
`

const Icon = styled.div`
  ${aspectRatio(353, 221)};
  width: 100%;
`

const Icon_Content = styled.div`
  ${aspectRatioChild()};

  > * {
    width: 100%;
    height: 100%;
  }
`

const Main = styled.div`
  margin-top: 24px;
  @media ${mq.and(mobile)} {
    margin-top: 16px;
  }
`

const Body = styled(Typography)`
  text-align: center;
`

export default ThemeConsumer(ContractCard)
